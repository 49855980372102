import store from '@/store/index.js'
let dic = store.state.dic;
export default function Configs(that) {
  const searchItems = [
    {
      label: "传感器",
      span: 8,
      model: "sensorId",
      clearable: true,
      type: "select",
      options: [],
      change:val => that.getSensorDetail(val,'select')
    },
    {
      label: "测点",
      span: 8,
      model: "pointId",
      clearable: true,
      type: "select",
      options: [],
    },
    {
      label: "摄像头",
      span:8,
      type: "select",
      model: "videoId",
      options: [],
      clearable: true,
    },
  ];
  const tableColumns = [
    {
      label: "传感器名称",
      prop: "sensorName",
      align: "center",
      show: true
    },
    {
      label: "测点名称",
      prop: "pointName",
      align: "center",
      show: true
    },
    {
      label: "摄像头名称",
      prop: "videoName",
      align: "center",
      show: true
    },
    {
      label: "操作",
      name: 'custom',
      align: "center",
      show: true
    },
  ];
  return {
    searchItems,
    tableColumns
  };
}
